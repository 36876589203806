#input_area{
  height: 10rem;

}

.num_selected_box {
  margin-top: 1rem;
  height: 2rem;
  width: 2rem;
}

